import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'
import {hasIntersectionObserver} from '../../js/window-has';

export default class extends Controller {

    static targets = ['skeleton', 'pagination', 'currentPage', 'prevPageAnchor', 'nextPageAnchor'];
    paginationPage = 1;
    canonical = null;

    static values = {
        url: String,
        rendering: {type: Boolean, default: false},
        hideAdv: {type: Boolean, default: false},
        page: Number,
        maxPage: Number,
        category: String,
        advSection: {type: String, default: 'homepage'},
        totalPages: {type: Number, default: 0}
    }

    async connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                rootMargin: "100%",
            })
        }

        setTimeout(() => {
            if (this.isVisible() && this.renderingValue === false) {
                this.appear();
            }
        }, 1_000)


        this.canonical = window.location.href

    }

    async appear() {

        // if in rendering old iteration
        if (this.renderingValue === true) {
            return;
        }

        this.paginationPage = Math.ceil(this.pageValue / 2) + 1

        console.log('pagination', this.paginationPage);

        this.renderingValue = true;

        let params = {};
        params.page = this.pageValue;
        params.advSection = this.advSectionValue;
        if (this.hideAdvValue) {
            params.hideAdv = true;
        }

        if (this.categoryValue) {
            params.category = this.categoryValue;
        }

        const urlParams = new URLSearchParams(params);

        const response = await fetch(`${this.urlValue}?${urlParams.toString()}`);

        // error
        if (!response?.ok) {
            this.renderingValue = false;
            return;
        }

        this._managePagination()

        // no more content
        if (this.pageValue === this.maxPageValue || response.status === 204) {
            this.renderingValue = true;
            this.skeletonTarget.remove()
            return;
        }

        this.element.insertAdjacentHTML('beforebegin', await response.text())

        this.pageValue++;
        this.renderingValue = false;

        if (this.isVisible()) {
            await this.appear();
        }

    }


    _managePagination() {
        if (!this.hasPaginationTarget) {
            return
        }

        console.log('pagination target', this.paginationTarget)
        console.log('pagination total page', this.totalPagesValue)
        console.log('pagination page', this.paginationPage)

        if (this.paginationPage < this.totalPagesValue) {
            let pathCurrent = this.canonical + '/' + this.paginationPage;
            let pathNext =this.canonical + '/' + (this.paginationPage + 1);
            let pathPrev = this.canonical + '/' + (this.paginationPage - 1);
            this.currentPageTarget.innerText = this.paginationPage;
            this.nextPageAnchorTarget.setAttribute("href", pathNext);
            this.prevPageAnchorTarget.setAttribute("href", pathPrev);
            this.prevPageAnchorTarget.classList.remove('cursor-not-allowed', 'bg-cm-2', 'hover:bg-gray2')
            this.prevPageAnchorTarget.classList.add('bg-cm', 'hover:bg-cm-readable')
            let images = this.prevPageAnchorTarget.querySelectorAll("img")
            if ( typeof images[0] !== 'undefined') {
              let img=   images[0].dataset.image
                images[0].setAttribute('src', img)
            }

            document.title = document.title + ' | Pagina ' + this.paginationPage
            history.replaceState(null, '', pathCurrent);
        } else {
            this.paginationTarget.setAttribute('hidden', 'hidden')
            console.log('hide pagination')
        }

    }

}